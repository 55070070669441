import React, {useEffect} from "react"
import {graphql} from "gatsby"
import {window, document} from "browser-monads"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import FirstSection from "../components/experiences/firsSection"
import SecondSection from "../components/experiences/secondSection"
import Menu from "../components/services/menuServices"

export const query = graphql`
query {
  sanityExperiences {
    subtitle
    text
    quote
    phrase
    author
    image {
      asset {
        fluid(maxWidth: 1080) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    secondImage {
      asset {
        fluid(maxWidth: 2240) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
  }
}
`

const Experiences = ({data}) => {
  const {subtitle, text, quote, phrase, author, image, secondImage} = data.sanityExperiences

  useEffect(() => {
    window.onscroll = () => {
      Array.prototype.forEach.call(document.querySelectorAll(".experiences-wrapper"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(0, ${5 * t}%, 0) scale(1)`
        }
      }))
      Array.prototype.forEach.call(document.querySelectorAll(".experiences-second-wrapper"), (function(e) {
        if (e.getBoundingClientRect().top < window.innerHeight && e.getBoundingClientRect().bottom > 0) {
          let t = Math.round(1e5 * ((-e.getBoundingClientRect().top - window.innerHeight) / window.innerHeight * 2 + 3)) / 1e5;
          e.querySelector(".gatsby-image-wrapper").style.transform = `translate3d(45px, -${5 * t}%, 0) scale(1)`
        }
      }))
    }
  })

  return(
    <Layout>
      <SEO title="Experiences"/>

      <HeaderSection title="Experiences" description={subtitle}/>

      <FirstSection phrase={phrase} image={image}/>

      <SecondSection text={text} secondImage={secondImage} quote={quote} author={author}/>

      <Menu/>

    </Layout>
  )
}

export default Experiences
