import React from "react"
import Image from "../image"

const FirstSection = ({phrase, image}) => (
  <div className="experiences--first-section">
    <div className="experiences-wrapper">
      <Image fluid={image.asset.fluid} alt="Experience Ubuntu: Experiences"/>
    </div>
    <div className="exp-background grid-container-13">
      <p>{phrase}</p>
    </div>
  </div>
)

export default FirstSection
