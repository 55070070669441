import React from "react"

import Image from "../image"
import Form from "../form"

const SecondSection = ({text, secondImage, quote, author}) => (
  <div className="experiences--second-section grid-container-13">
    <div className="text-container">
      <p>{text}</p>
      <h3 data-sal="slide-down" data-sal-delay="150" data-sal-duration="800" data-sal-easing="ease">Ubuntu experiences coming soon</h3>
      <p>Be the first to know</p>
      <Form formID="1639375"/>
    </div>
    <div className="experiences-second-wrapper">
      <Image fluid={secondImage.asset.fluid} alt="Experience Ubuntu Experiences"/>
    </div>
    <div className="quote-container">
      <p>{quote}</p>
      <p>-{author}-</p>
    </div>
  </div>
)

export default SecondSection
