import React, {useState} from "react"

import Arrow from "../assets/svg/ubuntu-arrow-form.svg"

const Form = ({formID}) => {
  const
    [status, setStatus] = useState(null),
    [email, setEmail] = useState(''),
    [firstName, setName] = useState(''),
    FORM_URL = `https://app.convertkit.com/forms/${formID}/subscriptions`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    try {
      const response = await fetch(
        FORM_URL,
        {
          method: 'post',
          body: data,
          headers: {
            accept: 'application/json',
          },
        }
      );
      setEmail('');
      setName('');
      const json = await response.json();
      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
    }
  };

  const handleInputChange = event => {
    const {value, name} = event.target;
    if(name === "email_address") {
      setEmail(value);
    } else {
      setName(value);
    }
  }

  return (
    <React.Fragment>
      <form action={FORM_URL} method="post" onSubmit={handleSubmit}>
        <input
          type="text"
          name="fields[first_name]"
          placeholder="Name"
          onChange={handleInputChange}
          value={firstName}
          required
        />
        <input
          type="email"
          name="email_address"
          placeholder="Email"
          onChange={handleInputChange}
          value={email}
          required
        />
        <button type="submit" className="clickable">{formID === "1639380" ? "I wanna know more!" : "Subscribe"} <Arrow className="clickable"/></button>
      </form>
      {status === 'SUCCESS' && <p className="subscription success">Check your email to confirm your subscription.</p>}
      {status === 'ERROR' && <p className="subscription error">Something went wrong! try again.</p>}
    </React.Fragment>
  )
}

export default Form
